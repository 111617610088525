import { trigger } from '../js/events';
import { getPersistedValue } from './input-element-state';

function getErrorFeedbackElem(rootElem, depth = 0) {
  if (depth === 2) {
    return null;
  }
  const parentEl = rootElem.parentNode || rootElem.parentElement;
  return (
    parentEl.querySelector('[data-error-feedback]') ||
    getErrorFeedbackElem(parentEl, depth + 1)
  );
}

function getWarningFeedbackElem(rootElem, depth = 0) {
  if (depth === 2) {
    return null;
  }
  const parentEl = rootElem.parentNode || rootElem.parentElement;
  return (
    parentEl.querySelector('[data-warning-feedback]') ||
    getWarningFeedbackElem(parentEl, depth + 1)
  );
}

export function displayWarning({ inputElem, warningMessage }) {
  const feedbackElem = getWarningFeedbackElem(inputElem);
  const feedbackTextElem = feedbackElem && feedbackElem.querySelector('[data-warning-feedback-text]')
  if (!feedbackElem || !feedbackTextElem) {
    return
  }
  if (warningMessage) {
    inputElem.classList.add('has-warnings');
    if (feedbackElem) {
      feedbackElem.classList.remove('d-none')
      feedbackElem.classList.add('d-flex');
      feedbackTextElem.innerHTML = warningMessage;
    }
  } else {
    warningMessage = ''
    inputElem.classList.remove('has-warnings');
    if (feedbackElem) {
      feedbackElem.classList.add('d-none')
      feedbackElem.classList.remove('d-flex');
      feedbackTextElem.innerHTML = warningMessage;
    }
  }

}

function displayError({ inputElem, errorMessage = 'Unable to update value' }) {
  const invalidFeedbackElem = getErrorFeedbackElem(inputElem);
  inputElem.classList.add('has-errors');
  if (invalidFeedbackElem) {
    invalidFeedbackElem.classList.add('d-block');
    invalidFeedbackElem.innerHTML = errorMessage;
  }
}

function responseIsLikelyJson(response) {
  return (
    response &&
    response.headers &&
    response.headers.get('content-type') &&
    response.headers.get('content-type').includes('application/json')
  );
}

function parseDjangoErrors({ djangoErrors, inputElemName }) {
  // Django form error object has the following structure:
  // { errors: { field_name_1: Array<string>, field_name_2: Array<string> } }
  const fieldNames = Object.keys(djangoErrors);
  const fieldName = fieldNames.find(name => inputElemName.includes(name));
  const fieldErrors = djangoErrors[fieldName];
  if (Array.isArray(fieldErrors)) {
    return fieldErrors.map(obj => obj.message).join(', ');
  }
}

export function getErrorMessageFromResponse({ inputElem, response }) {
  if (responseIsLikelyJson(response)) {
    return response.json().then(body => {
      const djangoErrors = body.errors;
      if (djangoErrors) {
        return parseDjangoErrors({
          djangoErrors,
          inputElemName: inputElem.name,
        });
      }
      if (inputElem.name && body[inputElem.name]) {
        const errors = body[inputElem.name];
        if (Array.isArray(errors)) {
          return errors.join(' ');
        }
        return String(errors);
      }
      return body.error_message;
    });
  }
  return Promise.resolve();
}

function considerRevertValue(inputElem) {
  if (inputElem.type === 'checkbox') {
    const persistedData = getPersistedValue(inputElem);
    inputElem.checked = persistedData;
    trigger(inputElem, 'change');
  }
}

export function persistErrorHandler({ response, inputElem }) {
  return getErrorMessageFromResponse({ response, inputElem }).then(
    errorMessage => {
      displayError({ inputElem, errorMessage });
      considerRevertValue(inputElem);
    },
  );
}

export function persistSuccessHandler(response, inputElem){
  return getErrorMessageFromResponse({response, inputElem} ).then(
    warningMessage => {
      displayWarning({inputElem, warningMessage});
    },
  );
}

export function clearErrors(inputElem) {
  const invalidFeedbackElem = getErrorFeedbackElem(inputElem);
  if (!invalidFeedbackElem) {
    return;
  }

  invalidFeedbackElem.classList.remove('d-block');
  invalidFeedbackElem.innerText = '';
}
