const persistedDataMap = new Map();
const noop = () => {};

function get(inputElem) {
  return persistedDataMap.get(inputElem) || {};
}

function set(inputElem, type, newValue) {
  const elem = get(inputElem);
  persistedDataMap.set(
    inputElem,
    Object.assign({}, elem, {
      [type]: newValue,
    }),
  );
}

export function getPersistedValue(inputElem) {
  return get(inputElem).persistedValue;
}

export function isLoading(inputElem) {
  return get(inputElem).isLoading;
}

export function toggleLoading(inputElem, { isLoading }) {
  set(inputElem, 'isLoading', isLoading);
}

export function queueUpdate(inputElem, queuedValue) {
  set(inputElem, 'queuedValue', queuedValue);
}

export function clearQueue(inputElem) {
  const queuedValue = get(inputElem).queuedValue;
  if (queuedValue !== undefined) {
    set(inputElem, 'queuedValue', undefined);
  }
  return queuedValue;
}

export function setPersistedValue(inputElem, value) {
  set(inputElem, 'persistedValue', value);
}

export function setSuccessHandler(inputElem, handler) {
  set(inputElem, 'successHandler', handler);
}

export function setErrorHandler(inputElem, handler) {
  set(inputElem, 'errorHandler', handler);
}

export function getSuccessHandler(inputElem) {
  return get(inputElem).successHandler || noop;
}

export function getErrorHandler(inputElem) {
  return get(inputElem).errorHandler || noop;
}

export function clearAll() {
  persistedDataMap.clear();
}
