export default inputElem => {
  if (inputElem.type === 'checkbox') {
    return inputElem.checked;
  }
  if (inputElem.type === 'select-multiple') {
    if (inputElem.getAttribute('data-autocomplete-light-function')) {
      // Select 2 does multiple select does not change html value
      return $(inputElem).val()
    }
  }
  return inputElem.value;
};
