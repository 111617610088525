const SUCCESS_INDICATOR_PERIOD = 2000;

export default ({ inputElem }) => {
  const parentEl = inputElem.parentNode || inputElem.parentElement;
  const successIndicator = parentEl.querySelector(
    '[data-success-indicator]',
  );
  if (!successIndicator) {
    return;
  }

  successIndicator.classList.add('show');
  setTimeout(() => {
    successIndicator.classList.remove('show');
  }, SUCCESS_INDICATOR_PERIOD);
};
