import 'formdata-polyfill';
import { cloudFetch } from '../js/fetch-wrapper';
import { POST_AS_FORM, POST_AS_JSON } from './post-types';

function coerce(inputElem, value) {
  const attributeName = 'datacoerce'; // Unable to include dash in name via Django
  const coerceType = inputElem.getAttribute(attributeName);
  if (coerceType === 'nullify_blank' && value === '') {
    return null;
  }

  return value;
}

function formatValue(inputElem, value) {
  if (inputElem.type === 'checkbox') {
    return coerce(inputElem, !!inputElem.checked);
  }

  return coerce(inputElem, value);
}

export default ({ form, inputElem, postType, url, newValue }) => {
  const inputName = inputElem.name;

  function getHeaders() {
    if (postType === POST_AS_JSON) {
      return null;
    }

    return {
      'content-type': 'application/x-www-form-urlencoded',
    };
  }

  function getBody() {
    if (postType === POST_AS_FORM) {
      const data = new URLSearchParams();
      const formData = new FormData(form);
      for (const pair of formData) {
        data.append(pair[0], pair[1]);
      }
      return data.toString();
    }

    return {
      [inputName]: formatValue(inputElem, newValue),
    };
  }

  return cloudFetch(url, {
    body: getBody(),
    headers: getHeaders(),
    method: 'POST',
  });
};
