export const POST_AS_JSON = 'json';
export const POST_AS_FORM = 'form';

const methods = [POST_AS_FORM, POST_AS_JSON];

export function parse(str) {
  const method = methods.filter(method => method === str)[0];

  if(method) {
    return method
  }

  throw new Error(`Unknown post method ${str}`);
}
