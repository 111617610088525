import { debounce } from 'throttle-debounce';

import getInputElementValue from './get-input-element-value';
import { POST_AS_JSON } from './post-types';
import persistData from './persist-data';
import flashSuccessIndicator from './flash-success-indicator';
import { clearErrors, persistErrorHandler } from './persist-error-handler';
import {
  isLoading,
  clearQueue,
  queueUpdate,
  setPersistedValue,
  setSuccessHandler,
  setErrorHandler,
  getPersistedValue,
  getSuccessHandler,
  getErrorHandler,
  toggleLoading,
} from './input-element-state';
import { getDebounceDelayForElem } from './debounce-helper';

function toggleUiLoading({ inputElem, enable }) {
  const parentEl = inputElem.parentNode || inputElem.parentElement;
  const spinner = parentEl.querySelector('[data-spinner]');
  if (spinner) {
    spinner.classList[enable ? 'remove' : 'add']('d-none');
  }
}

function toggleUi({ form, enable, postType }) {
  if (postType === POST_AS_JSON) {
    return;
  }

  const elems = form.querySelectorAll('[name], button, input');
  elems.forEach(elem => {
    if (elem.type === 'checkbox') {
      if (window.console && window.console.warn) {
        window.console.warn(
          'Unable to set readonly on a checkbox. Form will not be disabled while posting!',
        );
      }
    }
    const tagName = elem.tagName.toLowerCase();
    const attribute = tagName === 'button' ? 'disabled' : 'readonly';
    if (enable) {
      elem.removeAttribute(attribute);
    } else {
      elem.setAttribute(attribute, attribute);
    }
  });
}

function prepareDomForAsyncRequest(inputElem) {
  clearErrors(inputElem);
  inputElem.classList.remove('has-errors');
  toggleUiLoading({ inputElem, enable: true });
}

function persistWithInputElemStateUpdates({
  form,
  inputElem,
  postType,
  url,
  newValue,
}) {
  toggleLoading(inputElem, { isLoading: true });

  return persistData({ form, inputElem, postType, url, newValue })
    .then((res) => {
      setPersistedValue(inputElem, newValue);
      const queuedValue = clearQueue(inputElem);
      if (queuedValue !== undefined) {
        return persistWithInputElemStateUpdates({
          form,
          inputElem,
          postType,
          url,
          newValue: queuedValue,
        });
      }
      return Promise.resolve(res);
    })
    .finally(() => {
      toggleLoading(inputElem, { isLoading: false });
    });
}

function persistWithUiUpdates({ form, inputElem, postType, url, newValue }) {
  toggleUi({ form, enable: false, inputElem, postType });
  prepareDomForAsyncRequest(inputElem);
  persistWithInputElemStateUpdates({ form, inputElem, postType, url, newValue })
    .then((response) => {
      flashSuccessIndicator({ inputElem });
      getSuccessHandler(inputElem)(response, inputElem);
    })
    .catch(data => {
      persistErrorHandler({ response: data.response, inputElem });
      getErrorHandler(inputElem)(inputElem);
    })
    .finally(() => {
      toggleUi({ form, enable: true, inputElem, postType });
      toggleUiLoading({ inputElem, enable: false });
    });
}

export default function asyncFormEventHandler({
  form,
  inputElem,
  postType,
  url,
  successHandler,
  errorHandler,
}) {
  setPersistedValue(inputElem, getInputElementValue(inputElem));
  if (successHandler !== undefined) {
    setSuccessHandler(inputElem, successHandler);
  }

  if (errorHandler !== undefined) {
    setErrorHandler(inputElem, errorHandler);
  }

  const delay = getDebounceDelayForElem(inputElem);
  const debouncedPersistWithUiUpdates = debounce(delay, persistWithUiUpdates);

  return function handleEvent(e) {
    const newValue = getInputElementValue(e.target);
    const oldValue = getPersistedValue(e.target);


    if (newValue === oldValue && !isLoading(inputElem)) {
      clearErrors(inputElem);
      inputElem.classList.remove('has-errors');
      return;
    }

    if (isLoading(inputElem)) {
      queueUpdate(inputElem, newValue);
      return;
    }

    debouncedPersistWithUiUpdates({ form, inputElem, postType, url, newValue });
  };
}
