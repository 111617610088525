const DEBOUNCE_WAIT_QUICK = 700;
const DEBOUNCE_WAIT_SLOW = 2000;

export function getDebounceDelayForElem(inputElem) {
  if (
    inputElem.type === 'checkbox' ||
    inputElem.tagName.toLowerCase() === 'select'
  ) {
    return DEBOUNCE_WAIT_QUICK;
  }

  return DEBOUNCE_WAIT_SLOW;
}
