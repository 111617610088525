import asyncFormEventHandler from './async-form-event-handler';

function getEventType(inputElem) {
  if (inputElem.type === 'checkbox') {
    return 'click';
  }

  const tagName = inputElem.tagName.toLowerCase();

  if (tagName === 'select' || inputElem.type === 'date') {
    return 'change';
  }

  if (inputElem.type === 'text' || tagName === 'textarea') {
    return 'input';
  }

  return 'change';
}

export function initAsyncField({
  form,
  inputElem,
  postType,
  successHandler,
  errorHandler,
  url,
}) {
  const eventHandler = asyncFormEventHandler({
    form,
    inputElem,
    postType,
    successHandler,
    errorHandler,
    url,
  });
  const eventType = getEventType(inputElem);
  inputElem.addEventListener(eventType, eventHandler);
}

export function initAsyncForm({
  form,
  postType,
  successHandler,
  errorHandler,
}) {
  const url = form.getAttribute('data-url');
  form.querySelectorAll('[name]').forEach((inputElem) =>
    initAsyncField({
      form,
      inputElem,
      postType,
      successHandler,
      errorHandler,
      url,
    })
  );
}
